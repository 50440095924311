import {
    addDuration,
    type DayName,
    getDayIndex,
    getLocalDayName,
    now,
    parseIsoDate,
    parseIsoDateTime,
    serializeUtcIsoDateTime,
    toIsoDateString,
} from "../datetime";
import {
    type Child,
    type Command, Completable,
    DEFAULT_LOCATION,
    type DoneState,
    type Id,
    type Location,
    type Lookup,
    type Parent,
    ROOT,
    type Scheduled,
    type ScheduledId
} from "./data";
import {type ReadonlyDomainState} from "./logic";
import {maybeUndef} from "../util";
import {DAY_IN_MS} from "../constants";
import {SCHEDULING_SERVICE} from "../../client/client-config";

export async function buildScheduledCommands<Tx>(tx: Tx, state: ReadonlyDomainState<Tx>, today: Date): Promise<readonly Command[]> {
    const schedule = await state.getSchedule(tx);
    const lastExecuted = maybeUndef(await state.getConfig(tx, "lastScheduleExecution"), defaultLastScheduleExecution(), parseIsoDateTime);
    if (sameDate(lastExecuted, today)) {
        return [];
    }

    const commands: Command[] = [];
    for (const current of getRange(lastExecuted, today)) {
        for (const scheduled of Object.values(schedule)) {
            const dayName = getLocalDayName(current);
            const week = Math.ceil(current.getDate() / 7);

            for (const recurrence of scheduled.recurrence) {
                switch (recurrence.type) {
                    case "once":
                        if (!sameDate(parseIsoDate(recurrence.date), current)) {
                            break;
                        }
                        commands.push(...await buildScheduledCommand(tx, state, scheduled));
                        break;

                    case "every-n-days":
                        const daysSinceExecuted = getDaysBetween(parseIsoDate(recurrence.ref), current);
                        if ((daysSinceExecuted % recurrence.n) !== 0) {
                            break;
                        }
                        commands.push(...await buildScheduledCommand(tx, state, scheduled));
                        break;

                    case "every-week":
                        if (!recurrence.day.includes(dayName)) {
                            break;
                        }
                        commands.push(...await buildScheduledCommand(tx, state, scheduled));
                        break;

                    case "every-month-on-nth-day-number":
                        if (recurrence.day !== current.getDate()) {
                            break;
                        }
                        commands.push(...await buildScheduledCommand(tx, state, scheduled));
                        break;

                    case "every-month-on-nth-day-name":
                        if (recurrence.day !== dayName || (recurrence.week === "last" ? !isLastDayNameOfMonth(recurrence.day, current) : recurrence.week !== week)) {
                            break;
                        }
                        commands.push(...await buildScheduledCommand(tx, state, scheduled));
                        break;

                    case "every-year":
                        if (!sameDate(new Date(`${current.getFullYear()}-${recurrence.date}`), current)) {
                            break;
                        }
                        commands.push(...await buildScheduledCommand(tx, state, scheduled));
                        break;

                    default: {
                        // eslint-disable-next-line @typescript-eslint/no-unused-vars
                        const _: never = recurrence;
                        // noinspection ExceptionCaughtLocallyJS
                        throw new Error(`Unexpected object: ${JSON.stringify(recurrence)}`);
                    }
                }
            }
        }
    }

    commands.push({
        type: "RecordScheduleExecuted",
        date: serializeUtcIsoDateTime(today),
    });

    return commands;
}

async function buildScheduledCommand<Tx>(tx: Tx, state: ReadonlyDomainState<Tx>, scheduled: Scheduled<ScheduledId, readonly Lookup<Id>[]>): Promise<Command[]> {
    const completable: Completable = scheduled.completable || "is-completable";
    const commands: Command[] = [];
    const child = `s:${scheduled.id}` as Child<Id>;
    const existingItem = await state.getItem(tx, child);
    if (existingItem === undefined) {
        // add it
        for (let i = 0; i < scheduled.parents.length; i++) {
            const parent = scheduled.parents[i] as Parent<Id>;
            const parentNewChildLocation = parent === ROOT
                ? (await state.getRoot(tx)).newChildLocation
                : (await state.getItemThrows(tx, "parent", parent)).newChildLocation;

            if (i === 0) {
                const noteData = {title: scheduled.title};
                const todoData = {
                    doneState: "todo" as DoneState,
                };
                const newChildLocation = DEFAULT_LOCATION;

                commands.push({
                    type: "AddChild",
                    parent,
                    child,
                    noteData,
                    ...(completable === "is-completable" ? {todoData} : {}),
                    newChildLocation,
                    location: parentNewChildLocation,
                    source: "schedule",
                });
            } else {
                commands.push({
                    type: "AttachChild",
                    parent,
                    child,
                    location: parentNewChildLocation,
                    source: "schedule",
                });
            }
        }
    } else {
        // check it has all of its required parents
        for (const parent of scheduled.parents) {
            const [parentNewChildLocation, parentChildren] = await getParentBits(state, tx, parent);
            if (!existingItem.parents.includes(parent)) {
                commands.push({
                    type: "AttachChild",
                    parent,
                    child,
                    location: parentNewChildLocation,
                    source: "schedule",
                });
            } else {
                const firstChild = parentChildren[0] as Child<Id>;
                const lastChild = parentChildren[parentChildren.length - 1] as Child<Id>;
                if (parentNewChildLocation === "top" && firstChild !== child) {
                    commands.push({
                        type: "ReorderChild",
                        parent,
                        child,
                        position: "above",
                        sibling: firstChild,
                    });
                } else if (parentNewChildLocation === "bottom" && lastChild !== child) {
                    commands.push({
                        type: "ReorderChild",
                        parent,
                        child,
                        position: "below",
                        sibling: lastChild,
                    });
                }
            }
        }

        switch (completable) {
            case "is-completable":
                if (existingItem.todoData === undefined) {
                    // make it completable
                    commands.push({
                        type: "EditCompletable",
                        item: child,
                        from: "is-not-completable",
                        to: "is-completable",
                        source: "schedule",
                    });
                } else if (existingItem.todoData.doneState === "done") {
                    // mark it "todo"
                    commands.push({
                        type: "EditDone",
                        item: child,
                        from: "done",
                        to: "todo",
                        source: "schedule",
                    });
                }
                break;

            case "is-not-completable":
                if (existingItem.todoData !== undefined) {
                    // make it not-completable
                    commands.push({
                        type: "EditCompletable",
                        item: child,
                        from: "is-completable",
                        to: "is-not-completable",
                        source: "schedule",
                    });
                }
                break;

            default: {
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                const _: never = completable;
                // noinspection ExceptionCaughtLocallyJS
                throw new Error(`Unexpected object: ${JSON.stringify(completable)}`);
            }
        }
    }

    const recurrence = scheduled.recurrence.flatMap(r => {
        switch (r.type) {
            case "once":
                return [];
            default:
                return [r];
        }
    });

    if (recurrence.length === 0) {
        // remove the scheduled item
        commands.push({
            type: "RemoveScheduled",
            id: scheduled.id,
        });
    } else if (recurrence.length < scheduled.recurrence.length) {
        // replace the scheduled item with the stripped-down recurrence
        commands.push({
            type: "EditScheduled",
            scheduled: {...scheduled, recurrence},
        });
    }

    return commands;
}

function sameDate(x: Date, y: Date): boolean {
    return x.getFullYear() === y.getFullYear() && x.getMonth() === y.getMonth() && x.getDate() === y.getDate();
}

function trimDateToMidnight(date: Date): Date {
    const d = new Date(date);
    d.setHours(0, 0, 0, 0);
    return d;
}

function getRange(fromExclusive: Date, toInclusive: Date): readonly Date[] {
    const trimmedFrom = trimDateToMidnight(fromExclusive);
    const trimmedTo = trimDateToMidnight(toInclusive);
    if (!(trimmedFrom < trimmedTo)) {
        throw new Error(`From (${toIsoDateString(trimmedFrom)}) must be earlier than ${toIsoDateString(trimmedTo)}`);
    }

    const dateRange: Date[] = [];
    const currentDate = new Date(fromExclusive);
    do {
        currentDate.setDate(currentDate.getDate() + 1);
        dateRange.push(new Date(currentDate));
    } while (!sameDate(currentDate, toInclusive))

    return dateRange;
}

function getDaysBetween(x: Date, y: Date): number {
    const x_ = new Date(x);
    const y_ = new Date(y);
    x_.setHours(0); x_.setMinutes(0); x_.setSeconds(0); x_.setMilliseconds(0);
    y_.setHours(0); y_.setMinutes(0); y_.setSeconds(0); y_.setMilliseconds(0);

    return Math.round((y_.valueOf() - x_.valueOf()) / DAY_IN_MS);
}

export function defaultLastScheduleExecution() {
    if (SCHEDULING_SERVICE) {
        return addDuration(now(), "d", -1);
    }

    return new Date("1970-01-01T00:00:00Z");
}

function isLastDayNameOfMonth(day: DayName, current: Date): boolean {
    const d = new Date(current);
    const dayIndex = getDayIndex(day);
    const month = current.getMonth();
    for (let i = 0; i < 31; i++) {
        d.setDate(d.getDate() + 1);
        if (d.getMonth() !== month) {
            return true;
        }
        if (d.getDay() === dayIndex) {
            return false;
        }
    }

    throw new Error("isLastDayNameOfMonth: should be unreachable");
}

export function numericalSuffix(n: number | string): string {
    const str = typeof n === "string" ? n : n.toFixed(0).toString();
    switch (str) {
        case "11":
        case "12":
        case "13":
            return "th";
    }
    const last = str.substring(str.length - 1, str.length);
    switch (last) {
        case "1": return "st";
        case "2": return "nd";
        case "3": return "rd";
        default:  return "th";
    }
}

async function getParentBits<Tx>(state: ReadonlyDomainState<Tx>, tx: Tx, parent: Parent<Id>): Promise<[Location, readonly Child<Id>[]]> {
    if (parent === ROOT) {
        const root = await state.getRoot(tx);
        return [root.newChildLocation, root.children];
    } else {
        const item = await state.getItemThrows(tx, "parent", parent);
        return [item.newChildLocation, item.children];
    }
}