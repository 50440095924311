import {type IxItemThin} from "./types";
import {
    type AccountConfiguration,
    type Entrypoint,
    type Id,
    type Lookup,
    type NamedGeoLocation,
    type Scheduled,
    type ScheduledId
} from "../common/domain/data";
import {Store} from "./store";
import {lru} from "./lru";
import {mutable} from "./mutable";

export type EntrypointKey = string;
export type EntrypointData = {id: EntrypointKey, val: Entrypoint<Id>};

export type EntrypointStore = FancyObjectStore<EntrypointData, EntrypointKey> & { readonly __tag_ObjectStoreEntrypoints: unique symbol };
export type ItemsStore = FancyObjectStore<IxItemThin, Id> & { readonly __tag_ObjectStoreItems: unique symbol };
export type GeoLocationStore = FancyObjectStore<NamedGeoLocation, string> & { readonly __tag_ObjectStoreGeoLocations: unique symbol };
export type ScheduledStore = FancyObjectStore<Scheduled<ScheduledId, readonly Lookup<Id>[]>, ScheduledId> & { readonly __tag_ObjectStoreGeoLocations: unique symbol };
export type ConfigStore = FancyObjectStore<{ key: keyof AccountConfiguration, value: any }, string> & { readonly __tag_ObjectStoreConfig: unique symbol };

type ObjectStores = {
    "entrypoints": EntrypointStore,
    "items": ItemsStore,
    "geoLocations": GeoLocationStore,
    "schedule": ScheduledStore,
    "config": ConfigStore,
}

export const db = open("mapdone", "10", db => {
    db.createObjectStore("entrypoints", {keyPath: "id"});
    const items = db.createObjectStore("items", {keyPath: "id"});
    items.createIndex("title", "noteData.title", {unique: false});
    db.createObjectStore("geoLocations", {keyPath: "name"});
    db.createObjectStore("schedule", {keyPath: "id"});
    db.createObjectStore("config", {keyPath: "key"});

    /*
    evstream.transaction.oncomplete = (event) => {
        db.transaction("evstream", "readwrite", )
    }
    */
}).then(db => new FancyIDB(db));

async function open(name: string, version: string, onUpgradeNeeded: (db: IDBDatabase, ev: IDBVersionChangeEvent) => void): Promise<IDBDatabase> {
    if (version.match(/\D/)) {
        throw new Error("Version must be a number");
    }
    const request = window.indexedDB.open(name, Number.parseInt(version));
    return new Promise((res, rej) => {
        request.onupgradeneeded = ev => {
            onUpgradeNeeded(eval("ev.target.result") as IDBDatabase, ev);
        };
        request.onsuccess = () => {
            request.result.addEventListener("versionchange", () => {
                request.result.close();
            });

            res(request.result);
        };
        request.onerror = () => rej(request.error);
    });
}

export async function deleteDatabase(name: string): Promise<void> {
    return promisify(indexedDB.deleteDatabase(name)).then(undefined);
}

async function promisify<T>(request: IDBRequest<T>): Promise<T> {
    return new Promise((res, rej) => {
        request.onsuccess = () => res(request.result);
        request.onerror = () => rej(request.error);
    });
}

export class FancyIDB {
    private db: IDBDatabase;

    constructor(db: IDBDatabase) {
        this.db = db;
    }

    async transaction<T, Name extends keyof ObjectStores>(mode: IDBTransactionMode, storeNames: Name[], scope: (tx: FancyTransaction) => Promise<T>): Promise<T> {
        const transaction = await this.getTransaction(storeNames, mode);

        try {
            const result = await scope(transaction);
            transaction.commitSearch();
            return result;
        } catch (e) {
            transaction.abort();
            console.error("Transaction aborted")
            throw e;
        }
    }

    private async getTransaction<Name extends keyof ObjectStores>(storeNames: Name[], mode: IDBTransactionMode): Promise<FancyTransaction> {
        try {
            return new FancyTransaction(this.db.transaction(storeNames, mode));
        } catch (e) {
            console.error(e);
            await deleteDomainData();
            throw e;
        }
    }

    close(): void {
        this.db.close();
    }
}

export class FancyTransaction {
    private transaction: IDBTransaction;
    private searchIndexPendingChanges = false;

    constructor(idbTransaction: IDBTransaction) {
        this.transaction = idbTransaction;
    }

    table<Name extends keyof ObjectStores>(name: Name): ObjectStores[Name] {
        if (!this.transaction.objectStoreNames.contains(name)) {
            throw new Error(`Not found in transaction object stores: ${name}, maybe add it to domainTx()?`);
        }

        return new FancyObjectStore(this.transaction.objectStore(name)) as ObjectStores[Name];
    }

    async abort(): Promise<void> {
        if (this.searchIndexPendingChanges) {
            await mutable.search.rebuild();
            lru.clear();
        }

        return this.transaction.abort();
    }

    searchIndexHasPendingChanges(): void {
        this.searchIndexPendingChanges = true;
    }

    commitSearch(): void {
        if (this.searchIndexPendingChanges) {
            mutable.search.commit();
        }
    }
}

export class FancyObjectStore<Value, Key extends IDBValidKey> {
    private objectStore: IDBObjectStore;

    constructor(idbObjectStore: IDBObjectStore) {
        this.objectStore = idbObjectStore;
    }

    async get(key: Key): Promise<Value | undefined> {
        return promisify(this.objectStore.get(key));
    }

    async put(value: Value): Promise<Key> {
        return promisify(this.objectStore.put(value)) as Promise<Key>;
    }

    async delete(key: Key): Promise<void> {
        return promisify(this.objectStore.delete(key));
    }

    async bulkGet(keys: Key[]): Promise<(Value | undefined)[]> {
        return Promise.all(keys.map(k => this.get(k)));
    }

    async bulkPut(values: Value[]): Promise<Key[]> {
        return Promise.all(values.map(v => this.put(v)));
    }

    async each(callback: (value: Value, key: Key) => boolean | void): Promise<void> {
        return new Promise(resolve => {
            const request = this.objectStore.openCursor();
            request.onsuccess = () => {
                const cursor = request.result;
                if (cursor) {
                    const terminateEarly = callback(cursor.value, cursor.key as Key);
                    if (terminateEarly) {
                        resolve();
                    } else {
                        cursor.continue();
                    }
                } else {
                    resolve();
                }
            };
        })
    }

    async toArray(): Promise<Value[]> {
        const values: Value[] = [];
        return this.each((v) => {
            values.push(v);
        }).then(() => values);
    }

    async count(): Promise<number> {
        return promisify(this.objectStore.count());
    }

    async clear(): Promise<void> {
        return promisify(this.objectStore.clear());
    }

    async first(): Promise<Value | undefined> {
        let x: Value | undefined = undefined;
        return this.each(v => { x = v; return true }).then(() => x);
    }
}

export async function deleteDomainData(): Promise<void> {
    console.error(new Error("Deleting domain data"));

    try {
        (await db).close();
        await deleteDatabase("mapdone");
    } catch (_e) {
        debugger;
    }
    Store.removeItem("clientState");
    Store.removeItem("searchIndex");
    lru.clear();

    window.location.href = "/";
}
