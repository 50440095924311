import {type Child, type Detail, type Id, type Location, type Lookup, type Parent, ROOT, type Title} from "../../../../common/domain/data";
import React, {type KeyboardEventHandler, type ReactElement, useEffect, useRef, useState} from "react";
import {type ItemGraph, type Model} from "../../../types";
import {type GraphPath, toUrlRepr} from "../../../../common/urlpath";
import {
    Badge,
    Button,
    Col,
    Form,
    FormCheck,
    FormControl,
    type FormControlProps,
    FormGroup,
    FormText,
    Row
} from "react-bootstrap";
import {domainTx, handleCommands} from "../../../ui-state";
import {updateNewChildLocation} from "./util";
import {isSomething, maybeUndef} from "../../../../common/util";
import {
    autoFocus, focusFirst, getFormValues,
    getParentForm,
    searchBlocklistChildren,
    searchBlocklistItem,
    searchBlocklistParents, toLookup, xpath
} from "../../util";
import {clearItemTitleState, ItemTitle, type ItemTitleState} from "../../../components/ItemTitle";
import { NEW_CHILD_TITLE_PLACEHOLDER } from "../../../../common/config";
import {normalizeDetail, normalizeTitle} from "../../../../common/domain/logic";
import {uuidv4} from "../../../../common/uuid";
import {mutable} from "../../../mutable";
import {Markdown} from "../../../components/Markdown";

type ItemParentBadgesProps = {
    parents: readonly Id[],
    currParent: Lookup<Id>,
}
export function ItemParentBadges({parents, currParent}: ItemParentBadgesProps): ReactElement {
    return <>{parents
        .filter(p => currParent !== p)
        .map(p => mutable.search.get(p))
        .filter(isSomething)
        .map(p => <a
                className={"parent-badge"}
                href={"#" + toUrlRepr({type: "Graph", breadcrumbs: [p.id]})}
                key={`parent-${p.id}`}
            >
                <Badge bg="info">{p.noteData.title}</Badge>
            </a>
        )}</>;
}

export function detailOpt(): ReactElement {
    return (
        <FormText
            key={"detail-opt"}
            className={"text-muted"}
        >
            optional, supports <a key={"gfm-link"} target={"_blank"}
                                  href={"https://docs.github.com/en/get-started/writing-on-github/getting-started-with-writing-and-formatting-on-github/basic-writing-and-formatting-syntax"}>GitHub-flavored
            markdown</a>
        </FormText>
    );
}

export function detailPreviewCol(detail: string): ReactElement | undefined {
    if (detail.length === 0) {
        return undefined;
    }

    return (
        <Col key={"preview"} className={"d-md-block"}>
            <Markdown md={detail} />
        </Col>
    );
}

type AddChildFormProps = {
    model: Model,
    path: GraphPath,
    focus: ItemGraph,
    newChildLocation: Location,
    addChildItemTitleState: ItemTitleState
};
export function AddChildForm({model, path, focus, newChildLocation, addChildItemTitleState}: AddChildFormProps): ReactElement | null {
    const typedInto = useRef(false);
    const [input, _setInput] = addChildItemTitleState.input;
    const inputRef = useRef<HTMLDivElement>(null);
    const [detail, setDetail] = useState("");

    useEffect(() => {
        if (input.length > 0) {
            typedInto.current = true;
        }

        if (inputRef.current && (typedInto.current || autoFocus(model.windowSize))) {
            inputRef.current.focus();
        }
    }, [input, typedInto, model.windowSize]);

    const idBlockList = [
        ...searchBlocklistItem(model.graph),
        ...searchBlocklistParents(model.graph),
        ...searchBlocklistChildren(model.graph)
    ];

    return <div className={"add-child"} style={{marginBottom: "1em"}}>
        {addChildItemTitleState.input[0].length === 0
            ? <Form name={"add-child-mini"} onSubmit={ev => {
                ev.preventDefault();
                return submit(ev.target as HTMLFormElement);
            }}>
                <FormGroup key={"title"}>
                    <Row>
                        <Col key={"title"}>
                            <ItemTitle
                                state={addChildItemTitleState}
                                inputRef={inputRef}
                                name={"itemTitle"}
                                placeholder={NEW_CHILD_TITLE_PLACEHOLDER}
                                tabIndex={2}
                                idBlockList={idBlockList}
                                required
                                onItemChosen={chosen => {
                                    if (chosen === null || chosen.id === ROOT) return;

                                    void domainTx(tx => handleCommands(tx, [{
                                        type: "AttachChild",
                                        parent: toLookup(focus),
                                        child: chosen.id as Id as Child<Id>,
                                        location: newChildLocation,
                                    }]));

                                    clearItemTitleState(addChildItemTitleState);
                                    setDetail("");
                                }}
                            />
                        </Col>
                        <Col key={"submit"} xs={"auto"}>
                            <Button
                                key={"submit"}
                                variant={"primary"}
                                type={"submit"}
                            >+</Button>
                        </Col>
                    </Row>
                </FormGroup>
            </Form>
            : <Form name={"add-child"} onSubmit={ev => {
                ev.preventDefault();
                void submit(ev.target as HTMLFormElement);
            }}>
                <Row>
                    <Col>
                        <FormGroup
                            key={"completable"}
                            controlId={"completable"}
                        >
                            <FormCheck
                                key={"add-completable"}
                                name={"completable"}
                                label={"completable"}
                                type={"checkbox"}
                                defaultChecked={true}
                            />
                        </FormGroup>
                    </Col>
                    <Col>
                        <FormGroup
                            key={"location"}
                            controlId={"location"}
                        >
                            <Form.Select
                                aria-label="Child location"
                                name={"newChildLocation"}
                                value={newChildLocation}
                                onChange={ev => {
                                    const location = ev.target.value; // grab it before any re-render
                                    void domainTx(tx => updateNewChildLocation(tx, location, maybeUndef(focus, ROOT, item => item.id as Lookup<Id>)));
                                }}
                            >
                                <option value="bottom">Add at bottom</option>
                                <option value="top">Add at top</option>
                            </Form.Select>
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <FormGroup key={"title"}>
                        <Row>
                            <Col key={"title"}>
                                <ItemTitle
                                    state={addChildItemTitleState}
                                    autoFocus={true}
                                    name={"itemTitle"}
                                    placeholder={NEW_CHILD_TITLE_PLACEHOLDER}
                                    tabIndex={2}
                                    idBlockList={idBlockList}
                                    required
                                    onItemChosen={chosen => {
                                        if (chosen === null || chosen.id === ROOT) return;

                                        void domainTx(tx => handleCommands(tx, [{
                                            type: "AttachChild",
                                            parent: toLookup(focus),
                                            child: chosen.id as Id as Child<Id>,
                                            location: newChildLocation,
                                        }]));

                                        clearItemTitleState(addChildItemTitleState);
                                        setDetail("");
                                    }}
                                />
                                <FormText key={"title-req"} className={"text-muted"}>required</FormText>
                            </Col>
                            <Col key={"submit"} xs={"auto"}>
                                <Button
                                    key={"submit"}
                                    variant={"primary"}
                                    type={"submit"}
                                >+</Button>
                            </Col>
                        </Row>
                    </FormGroup>
                </Row>
                <Row>
                    <FormGroup key={"detail"} controlId={"detail"}>
                        <Row>
                            <Col key={"edit"}>
                                <EditDetail
                                    name={"detail"}
                                    tabIndex={3}
                                    value={detail}
                                    onCtrlEnter={ev => {
                                        ev.preventDefault();
                                        void submit(getParentForm(ev));
                                    }}
                                    onChange={ev => setDetail(ev.target.value)}
                                />
                                {detailOpt()}
                            </Col>
                            {detailPreviewCol(detail)}
                        </Row>
                    </FormGroup>
                </Row>
            </Form>}
    </div>;

    async function submit(addChildFormNode: HTMLFormElement): Promise<void> {
        if (!addChildFormNode.checkValidity()) {
            return;
        }

        const {completable, itemTitle, detail} = getFormValues(addChildFormNode);

        const normalizedTitle = normalizeTitle(itemTitle as Title);
        if (normalizedTitle === "") {
            return;
        }
        const normalizedDetail = normalizeDetail((detail === undefined ? "" : detail) as Detail);
        const randomId = uuidv4() as Child<Id>;
        const lastBreadcrumb = path.breadcrumbs[path.breadcrumbs.length - 1];

        await domainTx(tx => handleCommands(tx, [{
            type: "AddChild",
            parent: lastBreadcrumb === undefined
                ? ROOT
                : (lastBreadcrumb as Parent<Id>),
            child: randomId,
            noteData: {title: normalizedTitle, ...(normalizedDetail !== "" && {detail: normalizedDetail})},
            ...(completable !== "false" && {todoData: {doneState: "todo"}}),
            location: focus.newChildLocation,
        }]));

        clearItemTitleState(addChildItemTitleState);
        setDetail("");

        focusFirst(xpath(".//*[@name='title']", addChildFormNode));
    }
}

type EditDetailProps = {
    name: string,
    value: string,
    onCtrlEnter: KeyboardEventHandler<HTMLInputElement | HTMLTextAreaElement>,
    autoFocus?: boolean, // not sure why this is needed
} & Omit<FormControlProps, "as" | "onKeyDown" | "ref">;
export function EditDetail({name, value, className, style, placeholder = "detail", onCtrlEnter, ...props} : EditDetailProps): ReactElement {
    const textareaRef = useRef<HTMLTextAreaElement>(null);
    return <FormControl
        {...props}
        ref={textareaRef}
        as={"textarea"}
        onKeyDown={ev => {
            if (ev.key === "Enter" && (ev.ctrlKey || ev.metaKey)) {
                onCtrlEnter(ev);
                return;
            }

            replaceTabWithSpaces(ev, textareaRef);
        }}
        name={name}
        value={value}
        className={`font-monospace ${className}`}
        style={{
            ...style,
            minHeight: "5em",
            height: "90%",
            fontSize: "0.8rem",
        }}
        placeholder={placeholder}
    />;
}

const SPACES_FOR_TAB = 4;
const TAB_REPLACEMENT = " ".repeat(SPACES_FOR_TAB);
function replaceTabWithSpaces(ev: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>, textareaRef: React.RefObject<HTMLTextAreaElement | null>) {
    // modified from https://stackoverflow.com/a/57594754
    if (ev.key === 'Tab' && !ev.shiftKey) {
        ev.preventDefault();
        const value = textareaRef.current!.value;
        const selectionStart = textareaRef.current!.selectionStart;
        const selectionEnd = textareaRef.current!.selectionEnd;
        textareaRef.current!.value = value.substring(0, selectionStart) + TAB_REPLACEMENT + value.substring(selectionEnd);
        textareaRef.current!.selectionStart = selectionEnd + SPACES_FOR_TAB - (selectionEnd - selectionStart);
        textareaRef.current!.selectionEnd = selectionEnd + SPACES_FOR_TAB - (selectionEnd - selectionStart);
    }
    if (ev.key === 'Tab' && ev.shiftKey) {
        ev.preventDefault();
        const value = textareaRef.current!.value;
        const selectionStart = textareaRef.current!.selectionStart;
        const selectionEnd = textareaRef.current!.selectionEnd;

        const beforeStart = value
            .substring(0, selectionStart)
            .split('')
            .reverse()
            .join('');
        const indexOfTab = beforeStart.indexOf(TAB_REPLACEMENT);
        const indexOfNewline = beforeStart.indexOf('\n');

        if (indexOfTab !== -1 && indexOfTab < indexOfNewline) {
            textareaRef.current!.value =
                beforeStart
                    .substring(indexOfTab + SPACES_FOR_TAB)
                    .split('')
                    .reverse()
                    .join('') +
                beforeStart
                    .substring(0, indexOfTab)
                    .split('')
                    .reverse()
                    .join('') +
                value.substring(selectionEnd);

            textareaRef.current!.selectionStart = selectionStart - SPACES_FOR_TAB;
            textareaRef.current!.selectionEnd = selectionEnd - SPACES_FOR_TAB;
        }
    }
}